import { css } from 'lit';

export const fontSizeXXXL = css`48px`;
export const fontSizeXXL = css`36px`;
export const fontSizeXL = css`24px`;
export const fontSizeL = css`18px`;
export const fontSizeML = css`16px`;
export const fontSizeM = css`14px`;
export const fontSizeS = css`12px`;

export const fontMixinS = () => css`
  font-size: ${fontSizeS};
  line-height: calc(${fontSizeS} * 1.2);
  letter-spacing: 0.04em;
`;

export const fontMixinM = () => css`
  font-size: ${fontSizeM};
  line-height: calc(${fontSizeM} * 1.2);
  letter-spacing: 0.04em;
`;
export const fontMixinML = () => css`
  font-size: ${fontSizeML};
  line-height: calc(${fontSizeML} * 1.2);
  letter-spacing: 0.04em;
`;

export const fontMixinL = () => css`
  font-size: ${fontSizeL};
  line-height: calc(${fontSizeL} * 1.2);
  letter-spacing: 0.04em;
`;

export const fontMixinXL = () => css`
  font-size: ${fontSizeXL};
  line-height: calc(${fontSizeXL} * 1.2);
  letter-spacing: 0.04em;
`;

export const fontMixinXXL = () => css`
  font-size: ${fontSizeXXL};
  line-height: calc(${fontSizeXXL} * 1.2);
  letter-spacing: 0.04em;
`;

export const fontMixinXXXL = () => css`
  font-size: ${fontSizeXXXL};
  line-height: calc(${fontSizeXXXL} * 1.2);
  letter-spacing: 0.04em;
`;

export const nunitoFont = css`
  @import url('https://fonts.googleapis.com/css2?family=Nunito+Sans&display=swap');
  :host {
    --nunito-font: 'Nunito Sans', sans-serif;
    font-family: var(--nunito-font);
  }
`;

export const montserratFont = css`
  @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&display=swap');
  :host {
    --monserrat-font: 'Montserrat', sans-serif;
    font-family: var(--monserrat-font);
  }
`;
