import { axios, Axios } from '@bundled-es-modules/axios';
import { getAccessToken } from './utils';
import { FUNCTIONS_ENDPOINT } from '../../constants';

class TrendscapeFunctionsClient {
  private client: Axios | null = null;

  public accessToken: string | null | undefined = null;

  public cubeToken: string | null | undefined = null;

  public initComplete: Promise<void>;

  constructor() {
    this.initComplete = this._setAxiosInstance();
  }

  async getTokens() {
    this.accessToken = await getAccessToken();
  }

  async _setAxiosInstance() {
    // @ts-ignore
    this.accessToken = await getAccessToken();

    this.client = axios.create({
      baseURL: FUNCTIONS_ENDPOINT,
      headers: {
        Authorization: `Bearer ${this.accessToken}`,
        ContentType: 'application/json',
      },
    });

    this.client.interceptors.request.use(
      async config => {
        await this.getTokens();

        // eslint-disable-next-line no-param-reassign
        config.headers.Authorization = `Bearer ${this.accessToken}`;

        return config;
      },
      error => Promise.reject(error)
    );
  }

  async queryMacroThemeBubbles(projectId, stakeholder, brandId, start, end) {
    await this.initComplete;

    const payload = {
      project_id: projectId,
      stakeholder,
      brand: brandId,
      start_date: start,
      end_date: end,
    };

    if (stakeholder === 'all' || stakeholder == null) {
      delete payload.stakeholder;
    }

    if (brandId === 'all' || brandId == null) {
      delete payload.brand;
    }

    if (start == null || start === '') {
      delete payload.start_date;
    }

    if (end == null || end === '') {
      delete payload.end_date;
    }

    return this.post('/query-mt-bubbles', payload);
  }

  async queryProjectInfo(projectId) {
    await this.initComplete;

    return this.post('/query-project-info', {
      project_id: projectId,
    });
  }

  async queryTrendscape(
    id: string,
    startDate?: string,
    endDate?: string,
    journeyMoment?: string,
    stakeholder?: string,
    brandId?: string
  ) {
    await this.initComplete;

    const payload = {
      project_id: id,
      start_date: startDate,
      end_date: endDate,
      journey_moment: journeyMoment,
      stakeholder: stakeholder,
      brand: brandId,
    };

    if (journeyMoment == null || journeyMoment.toUpperCase() === 'ALL') {
      delete payload.journey_moment;
    }

    if (brandId == null || brandId.toUpperCase() === 'ALL' || brandId === '') {
      delete payload.brand;
    }

    if (
      stakeholder == null ||
      stakeholder.toUpperCase() === 'ALL' ||
      stakeholder === ''
    ) {
      delete payload.stakeholder;
    }

    return this.post('/query-trendscape-v2', payload);
  }

  async queryStSide(projectId, subThemeId) {
    await this.initComplete;

    return this.post('/query-st-side', {
      project_id: projectId,
      sub_theme_id: subThemeId,
    });
  }

  async renameSubTheme(projectId, subThemeId, subThemeLabel) {
    await this.initComplete;

    return this.post('rename_sub_theme', {
      project_id: projectId,
      sub_theme_id: subThemeId,
      sub_theme_label: subThemeLabel,
    });
  }

  async get(path) {
    await this.initComplete;
    if (this.client == null) {
      throw new Error('Functions client not initialized');
    }
    const response = await this.client.get(path);
    if (response.data?.data) {
      return response.data?.data;
    }
    return response.data;
  }

  async post(path, body) {
    await this.initComplete;
    if (this.client == null) {
      throw new Error('Functions client not initialized');
    }
    const response = await this.client.post(path, body);
    if (response.data?.data) {
      return response.data?.data;
    }
    return response.data;
  }
}

export { TrendscapeFunctionsClient };
