/* eslint-disable no-case-declarations */
import { Notification } from '@vaadin/notification/vaadin-notification';
import { html, nothing, render } from 'lit';
import { xmark } from './assets/icons/xmark';

const routePrefix = '/app';
const storageKeys = {
  HASURA_TOKEN: 'nn-hasura-token',
  CUBE_TOKEN: 'nn-cube-token',
  SEARCH_TOKEN: 'nn-search-token',
};

const JSON_STRINGIFIED_PREFIX = 'nn-json-stringified-';

const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

function camelCaseToSpace(str) {
  // Insert a space before any uppercase letter preceded by a lowercase letter
  return str.replace(/([a-z])([A-Z])/g, '$1 $2');
}

const spaceToKebabCase = str => str.replace(/\s+/g, '-').toLowerCase();

const groupBy = (xs, key) =>
  xs.reduce((rv, x) => {
    // eslint-disable-next-line no-param-reassign
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});

function getQueryParams() {
  return new URLSearchParams(window.location.search);
}

const removeFromLocalStorage = key => {
  try {
    localStorage.removeItem(key);
    return true;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.warn(error);
    return undefined;
  }
};

const saveToLocalStorage = (key, value, deleteValueOnFail = false) => {
  let valueToStore = value;
  if (!key) {
    return;
  }
  if (typeof valueToStore === 'object' && valueToStore != null) {
    valueToStore = `${JSON_STRINGIFIED_PREFIX}${JSON.stringify(value)}`;
  }
  try {
    localStorage.setItem(key, valueToStore);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.warn(error);
    if (deleteValueOnFail) {
      removeFromLocalStorage(key);
    }
  }
};

const loadFromLocalStorage = key => {
  let value;
  if (!key) {
    return undefined;
  }
  try {
    value = localStorage.getItem(key);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.warn(error);
    return undefined;
  }
  if (value != null && value.startsWith(JSON_STRINGIFIED_PREFIX)) {
    const rawValue = value.split(JSON_STRINGIFIED_PREFIX)[1];
    if (rawValue != null) {
      value = JSON.parse(rawValue);
    }
  }
  return value;
};

const loadingAwareLabel = (label, loading) => {
  if (typeof label !== 'string') {
    return '';
  }

  return loading ? `Loading ${label.toLowerCase()} ...` : label;
};

const snakeCaseToTitleCase = s =>
  s.replace(/^_*(.)|_+(.)/g, (_, c, d) =>
    c ? c.toUpperCase() : ` ${d.toUpperCase()}`
  );

const formatDate = date => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

const getDateRange = period => {
  const now = new Date();
  let startDate;
  let endDate;

  switch (period.toLowerCase()) {
    case 'today':
      startDate = now;
      endDate = now;
      break;
    case 'yesterday':
      startDate = new Date(now.setDate(now.getDate() - 1));
      endDate = new Date(now.setDate(now.getDate() - 1));
      break;
    case 'this week':
      const startOfWeek = new Date(now.setDate(now.getDate() - now.getDay()));

      startDate = startOfWeek;
      endDate = new Date();
      break;
    case 'this month':
      startDate = new Date(now.getFullYear(), now.getMonth(), 1);
      endDate = new Date(now.getFullYear(), now.getMonth() + 1, 0);
      break;
    case 'this quarter':
      const quarterStartMonth = Math.floor(now.getMonth() / 3) * 3;

      startDate = new Date(now.getFullYear(), quarterStartMonth, 1);
      endDate = new Date(now.getFullYear(), quarterStartMonth + 3, 0);
      break;
    case 'this year':
      startDate = new Date(now.getFullYear(), 0, 1);
      endDate = new Date(now.getFullYear(), 11, 31);
      break;
    case 'last 7 days':
      startDate = new Date(now.setDate(now.getDate() - 7));
      endDate = new Date();
      break;
    case 'last 30 days':
      startDate = new Date(now.setDate(now.getDate() - 30));
      endDate = new Date();
      break;
    case 'last week':
      const lastWeekEnd = new Date(now);
      lastWeekEnd.setDate(now.getDate() - now.getDay());

      const lastWeekStart = new Date(lastWeekEnd);
      lastWeekStart.setDate(lastWeekEnd.getDate() - 6);

      startDate = lastWeekStart;
      endDate = lastWeekEnd;
      break;
    case 'last month':
      startDate = new Date(now.getFullYear(), now.getMonth() - 1, 1);
      endDate = new Date(now.getFullYear(), now.getMonth(), 0);
      break;
    case 'last quarter':
      const lastQuarterEndMonth = Math.floor(now.getMonth() / 3) * 3 - 1;
      startDate = new Date(now.getFullYear(), lastQuarterEndMonth - 2, 1);
      endDate = new Date(now.getFullYear(), lastQuarterEndMonth + 1, 0);
      break;
    case 'last year':
      startDate = new Date(now.getFullYear() - 1, 0, 1);
      endDate = new Date(now.getFullYear() - 1, 11, 31);
      break;
    case 'alltime':
      startDate = new Date(0, 0, 1);
      endDate = new Date(now.getFullYear(), 11, 31);
      break;
    default:
      throw new Error('Invalid period specified');
  }

  return {
    start_date: formatDate(startDate),
    end_date: formatDate(endDate),
  };
};

const filterArray = (array: Array<any>) => {
  if (Array.isArray(array)) {
    return array.filter(item => Object.keys(item).length !== 0);
  }

  return array;
};

const debounce = (context, func, wait, immediate): any => {
  let timeout;
  return function (...args) {
    return new Promise(resolve => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        timeout = null;
        if (!immediate) {
          Promise.resolve(func.apply(context, [...args])).then(resolve);
        }
      }, wait);
      if (immediate && !timeout) {
        Promise.resolve(func.apply(context, [...args])).then(resolve);
      }
    });
  };
};

const actionClicked = notification => {
  notification.close();

  document.dispatchEvent(
    new CustomEvent('notification-action-clicked', {
      bubbles: true,
      composed: true,
    })
  );
};

const notificationContent =
  (
    notification,
    title,
    actionLabel: string | null = null,
    actionFn: Function | null = null
  ) =>
  root => {
    render(
      html`
        <style>
          .notification__wrapper {
            display: flex;
            gap: 0.5rem;
            padding: 0.5rem 0.5rem;
          }

          .action-btn {
            cursor: pointer;
          }

          .action-btn:hover {
            text-decoration: underline;
          }
        </style>
        <div class="notification__wrapper">
          ${title}
          ${actionFn === null || actionLabel === null
            ? nothing
            : html`<div
                class="action-btn"
                @click=${() => {
                  actionClicked(notification);
                  actionFn();
                }}
                @keydown=${() => {
                  actionClicked(notification);
                  actionFn();
                }}
              >
                ${actionLabel}
              </div>`}
        </div>
        <nn-button
          @click=${() => notification.close()}
          ghost
          style="box-shadow: none;"
        >
          <nn-icon
            style="font-size: 16px; fill: white;"
            .svg=${xmark}
          ></nn-icon>
        </nn-button>
      `,
      root
    );
  };

const showErrorNotificationWithAction = (
  title: string,
  actionLabel: string | null = null,
  actionFn: Function | null = null,
  duration: number = 3000,
) => {
  const notification: any = Notification.show(title, {
    position: 'bottom-end',
    theme: 'error',
    duration,
  });
  notification.renderer = notificationContent(
    notification,
    title,
    actionLabel,
    actionFn
  );
};

const showErrorNotification = (title: string) => {
  const notification: any = Notification.show(title, {
    position: 'bottom-end',
    theme: 'error',
    duration: 5000,
  });
  notification.renderer = notificationContent(notification, title);
};

export {
  routePrefix,
  storageKeys,
  loadFromLocalStorage,
  saveToLocalStorage,
  delay,
  groupBy,
  getQueryParams,
  loadingAwareLabel,
  snakeCaseToTitleCase,
  formatDate,
  getDateRange,
  filterArray,
  debounce,
  camelCaseToSpace,
  spaceToKebabCase,
  showErrorNotification,
  showErrorNotificationWithAction,
};
