import { css } from 'lit';
import { lightBlack } from './colors';

const themeStyles = [
  css`
    html,
    body,
    :host {
      --nn-elements-font-family: 'Nunito Sans', sans-serif;
      --nn-elements-default-border-radius: 4px;
      --nn-elements-default-margin: 4px;
      --nn-elements-dark-text-color: ${lightBlack};
      --nn-primary-text-color: #f8f8f8;
      --nn-accent-color: #f82c91;

      /** Borders */
      --nn-border-radius-xs: 0.125rem;
      --nn-border-radius-sm: 0.25rem;
      --nn-border-radius-md: 0.5rem;
      --nn-border-radius-lg: 0.75rem;
      --nn-border-radius-xl: 1rem;
      --nn-border-radius-2xl: 1.5rem;
      --nn-border-radius-section: 10rem;
    }
  `,
];

export { themeStyles };
